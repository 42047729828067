import PropTypes from 'prop-types'
import React, {useMemo} from 'react'

import {
    Box,
    Flex,
    Heading,
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {colors} from '../../constants'
import Link from '../link'

const CategoryCard = ({data, index, total}) => {
    const cta = data?.cta
    const isLastCard = index === total - 1
    const isFirstCard = index === 0
    const height = {base: 'auto', lg: '180px', xl: '230px', '2xl': '320px'}
    const color = data?.theme?.color ?? colors.black

    const FirstCardValues = {
        base: isFirstCard ? '0' : '8px',
        lg: isFirstCard ? '0' : '12px'
    }

    const LastCardValues = {
        base: isLastCard ? '0' : '8px',
        lg: isLastCard ? '0' : '12px'
    }

    const cardBackground = useMemo(() => {
        if (data?.banner?.desktop?.url)
            return {
                backgroundImage: data?.banner?.desktop?.url
                    ? `url('${data?.banner?.desktop?.url}')`
                    : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }

        return {
            background: '#F4F3F1'
        }
    }, [data])

    return (
        <Box
            borderBottomLeftRadius={FirstCardValues}
            borderTopLeftRadius={FirstCardValues}
            borderBottomRightRadius={LastCardValues}
            borderTopRightRadius={LastCardValues}
            overflow="hidden"
            height={height}
            flexDirection={{base: 'column-reverse', lg: 'row'}}
            style={{cursor: 'pointer'}}
            onClick={() => (location.href = cta?.url ?? '#')}
            {...cardBackground}
        >
            <Flex
                height="100%"
                width="100%"
                gap="20px"
                alignItems="flex-end"
                paddingTop={{base: '16px', md: '24px', lg: '10px'}}
                paddingX={{base: '16px', md: '24px', lg: '14px', xl: '21px'}}
                paddingBottom={{base: '24px', md: '32px', lg: '13%'}}
            >
                <Flex
                    flexDirection="column"
                    gap={{base: '8px', lg: '6px', xl: '8px'}}
                >
                    <Heading
                        fontSize={{base: '16px', lg: '14px', xl: '16px'}}
                        fontFamily="Raleway"
                        fontWeight="300"
                    >
                        {data?.subTitle}
                    </Heading>
                    <Box
                        fontSize={{
                            base: '32px',
                            md: '32px',
                            lg: '24px',
                            xl: '30px'
                        }}
                        maxWidth="350px"
                        fontFamily="Raleway"
                        fontWeight="300"
                        color={color}
                        lineHeight={{base: '37px', md: '28px'}}
                        dangerouslySetInnerHTML={{
                            __html: data?.name
                        }}
                    />

                    <Flex width="fit-content">
                        <Link
                            {...cta}
                            buttonStyles={{
                                backgroundColor: colors.transparent,
                                fontFamily: 'Roboto',
                                fontWeight: '300',
                                fontSize: {
                                    base: '16px',
                                    lg: '16px',
                                    xl: '20px'
                                },
                                textDecoration: 'underline',
                                color
                            }}
                            type="tertiary"
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}

CategoryCard.displayName = 'CategoryCard'

CategoryCard.propTypes = {
    isBanner: PropTypes.bool,
    index: PropTypes.number,
    total: PropTypes.number,
    data: PropTypes.shape({
        name: PropTypes.string,
        subTitle: PropTypes.string,
        cta: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            externalUrl: PropTypes.string,
            openLinkInNewTab: PropTypes.bool,
            theme: PropTypes.string,
            type: PropTypes.string,
            isExternal: PropTypes.bool
        }),
        title: PropTypes.string,
        banner: PropTypes.shape({
            desktop: PropTypes.shape({
                url: PropTypes.string
            }),
            position: PropTypes.string
        }),
        theme: PropTypes.shape({
            backgroundColor: PropTypes.string,
            color: PropTypes.string
        })
    })
}

export default CategoryCard
